import React, { ComponentProps, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import {
  formatDate,
  getCurrentMonthStartDate,
  getCurrentWeekStartDate,
  getCurrentYearStartDate,
  getDateNDaysAgo,
  getLastMonthEndDate,
  getLastMonthStartDate,
  getLastWeekEndDate,
  getLastWeekStartDate,
  getLastYearEndDate,
  getLastYearStartDate,
  getNMonthAgoStartDate,
} from '../../utils/date';
import {
  CampaignRealtimeBlacklistRequest,
  createCampaignRealtimeBlacklistRequest,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import UserColumnSettingsModal from './UserColumnSettingsModal';
import CopyAdSlotsModal from './CopyAdSlotsModal';
import patchUpdateWhitelist, {
  isResponseWhitelistOK,
  AdSlotWhitelistPatchResponseError
} from '../../api/ad-slots/ad-slot.whitelist.patch';
import patchUpdateBlacklist, {
  isResponseBlacklistOK,
  AdSlotBlacklistPatchResponseError
} from '../../api/ad-slots/ad-slot.blacklist';
import patchIgnoreAI, {
  isResponseIgnoreAIOK,
  AdSlotIgnoreAIPatchResponseError
} from '../../api/ad-slots/ad-slot.ignore-ai.patch';
import patchRemoveIgnoreAI, {
  isResponseRemoveIgnoreAIOK,
  AdSlotRemoveIgnoreAIPatchResponseError
} from '../../api/ad-slots/ad-slot.remove-ignore-ai.patch';
import appContext from '../../utils/context/appContext';
import postCopyAdslots, {
  isResponseCopyOK,
  AdSlotCopyPostResponseError
} from '../../api/ad-slots/ad-slot.copy.post';
import { FormGroup, Input, Label } from 'reactstrap';

function setToMonday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 7;
  if (day !== 1) {
    date.setHours(-24 * (day - 1));
  }
  return date;
}

function setToSunday(date: Date, weekAgo: number = 0) {
  date.setDate(date.getDate() - 7 * weekAgo);
  const day = date.getDay() || 0;
  if (day !== 0) {
    date.setHours(24 * (7 - day));
  }
  return date;
}

function setToFirstMonthDate(date: Date, monthAgo: number = 0) {
  date.setMonth(date.getMonth() - monthAgo);
  date.setDate(1);
  return date;
}

function setToLastMonthDate(date: Date, monthAgo: number = 0) {
  return new Date(date.getFullYear(), date.getMonth() - monthAgo + 1, 0);
}

export default (props: ComponentProps<any> & { filter: CampaignRealtimeBlacklistRequest, setFilter: Dispatch<SetStateAction<any>>, headers: Array<string> , submitFilter: boolean, setSubmitFilter: () => {}}) => {
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const dataContext = useContext(appContext);


  function updateWhitelist() {
    if (props.rowsChosen?.length === 0) {
      dataContext.notify(`Источники не выбраны`, 'success');
      return
    }
    const resp = {...props.response};
    const updated: any = {};
    const bundles: any = [];
    props.rowsChosen.map((el: string) => {
      let params = el.split('%%');
      bundles.push({
        domainId: Number(params[0]),
        tagid: params[1],
        systemId: Number(params[2]),
        app: (params[3] === 'true') ? 1 : 0,
        id: props.response?.message.campaign.id
      });
      updated[String(params[0])+'%%'+String(params[1])+'%%'+String(params[2])+'%%'+String(params[3])] = 1;
    });
    patchUpdateWhitelist( {
      adSlots: bundles
    })
      .then(r => {
        if (!isResponseWhitelistOK(r)) {
          r = r as AdSlotWhitelistPatchResponseError;
          dataContext.notify(`Источники не обновлены`, 'success');
          return;
        }
        for (let i = 0; i < resp.message.data.length; i++) {
          let row = resp.message.data[i];
          if (updated[row.domainId+'%%'+row.tagid+'%%'+row.systemId+'%%'+row.isApp] !== 1) {
            continue;
          }
          row.list = 'white';
          row.banned = 'manager';
        }
        props.setResponse(resp);
        dataContext.notify(`Источники обновлены`, 'success');
      })
  }

  function updateBlacklist() {
    if (props.rowsChosen?.length === 0) {
      dataContext.notify(`Источники не выбраны`, 'success');
      return
    }
    const resp = {...props.response};
    const updated: any = {};
    const bundles: any = [];
    props.rowsChosen.map((el: string) => {
      let params = el.split('%%');
      bundles.push({
        domainId: Number(params[0]),
        tagid: params[1],
        systemId: Number(params[2]),
        app: (params[3] === 'true') ? 1 : 0,
        id: props.response?.message.campaign.id
      });
      updated[String(params[0])+'%%'+String(params[1])+'%%'+String(params[2])+'%%'+String(params[3])] = 1;
    });
    patchUpdateBlacklist({
      adSlots: bundles
    })
      .then(r => {
        if (!isResponseBlacklistOK(r)) {
          r = r as AdSlotBlacklistPatchResponseError;
          dataContext.notify(`Источники не обновлены`, 'success');
          return;
        }
        for (let i = 0; i < resp.message.data.length; i++) {
          let row = resp.message.data[i];
          if (updated[row.domainId+'%%'+row.tagid+'%%'+row.systemId+'%%'+row.isApp] !== 1) {
            continue;
          }
          row.list = 'black';
          row.banned = 'manager';
        }
        props.setResponse(resp);
        dataContext.notify(`Источники обновлены`, 'success');
      })
  }

  function removeFromIgnoreAi() {
    if (props.rowsChosen?.length === 0) {
      dataContext.notify(`Источники не выбраны`, 'success');
      return
    }
    const resp = {...props.response};
    const updated: any = {};
    const bundles: any = [];
    props.rowsChosen.map((el: string) => {
      let params = el.split('%%');
      bundles.push({
        domainId: Number(params[0]),
        tagid: params[1],
        systemId: Number(params[2]),
        app: (params[3] === 'true') ? 1 : 0,
        id: props.response?.message.campaign.id
      });
      updated[String(params[0])+'%%'+String(params[1])+'%%'+String(params[2])+'%%'+String(params[3])] = 1;
    });
    patchRemoveIgnoreAI({
      adSlots: bundles
    })
      .then(r => {
        if (!isResponseRemoveIgnoreAIOK(r)) {
          r = r as AdSlotRemoveIgnoreAIPatchResponseError;
          dataContext.notify(`Источники не обновлены`, 'success');
          return;
        }
        for (let i = 0; i < resp.message.data.length; i++) {
          let row = resp.message.data[i];
          if (updated[row.domainId+'%%'+row.tagid+'%%'+row.systemId+'%%'+row.isApp] !== 1) {
            continue;
          }
          row.ignoreAi.val = false;
        }
        props.setResponse(resp);
        dataContext.notify(`Источники обновлены`, 'success');
      })
  }

  function ignoreAi() {
    if (props.rowsChosen?.length === 0) {
      dataContext.notify(`Источники не выбраны`, 'success');
      return
    }
    const resp = {...props.response};
    const updated: any = {};
    const bundles: any = [];
    props.rowsChosen.map((el: string) => {
      let params = el.split('%%');
      bundles.push({
        domainId: Number(params[0]),
        tagid: params[1],
        systemId: Number(params[2]),
        app: (params[3] === 'true') ? 1 : 0,
        id: props.response?.message.campaign.id
      });
      updated[String(params[0])+'%%'+String(params[1])+'%%'+String(params[2])+'%%'+String(params[3])] = 1;
    });
    patchIgnoreAI( {
      adSlots: bundles
    })
      .then(r => {
        if (!isResponseIgnoreAIOK(r)) {
          r = r as AdSlotIgnoreAIPatchResponseError;
          dataContext.notify(`Источники не обновлены`, 'success');
          return;
        }
        for (let i = 0; i < resp.message.data.length; i++) {
          let row = resp.message.data[i];
          if (updated[row.domainId+'%%'+row.tagid+'%%'+row.systemId+'%%'+row.isApp] !== 1) {
            continue;
          }
          row.ignoreAi.val = true;
        }
        props.setResponse(resp);
        dataContext.notify(`Источники обновлены`, 'success');
      })
  }

  function calculateDatePeriodDiff(date: Date | null, filterParam: string) {
    const secondsInDay = 86400;
    let endDate = null;
    let startDate = null;
    if (filterParam === 'startDate') {
      startDate = date;
      endDate = new Date(props.filter.endDate);
    } else {
      endDate = date;
      startDate = new Date(props.filter.startDate);
    }

    if (!startDate || !endDate) {
      return null;
    }

    let mondayDate = setToMonday(new Date());
    let sundayDate = setToSunday(new Date());
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('curr_week');
      return;
    }

    mondayDate = setToMonday(new Date(), 1);
    sundayDate = setToSunday(new Date(), 1);
    if (Math.abs(endDate.getTime() - sundayDate.getTime()) < 86400000
      && Math.abs(startDate.getTime() - mondayDate.getTime()) < 86400000) {
      onDataRangeChange('last_week');
      return;
    }

    let firstDateMonth = setToFirstMonthDate(new Date(), 1);
    let lastDateMonth = setToLastMonthDate(new Date(), 1);
    if (Math.abs(endDate.getTime() - lastDateMonth.getTime()) < 86400000
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('last_month');
      return;
    }

    firstDateMonth = setToFirstMonthDate(new Date(), 2);
    lastDateMonth = setToLastMonthDate(new Date());
    if (((endDate.getTime() - lastDateMonth.getTime()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateMonth.getTime()) < 86400000) {
      onDataRangeChange('3_months');
      return;
    }

    let firstDateYear = getCurrentYearStartDate();
    if (((endDate.getTime() - Date.now()) < 86400000 || endDate.getTime() > Date.now())
      && Math.abs(startDate.getTime() - firstDateYear.getTime()) < 86400000) {
      onDataRangeChange('curr_year');
      return;
    }

    if ((endDate.getTime() - getLastYearEndDate().getTime()) < 86400000
      && Math.abs(startDate.getTime() - getLastYearStartDate().getTime()) < 86400000) {
      onDataRangeChange('last_year');
      return;
    }

    const diff = Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / 1000 / secondsInDay) + 1;
    switch (diff) {
      case 7:
        onDataRangeChange('7_days');
        return;
      case 30:
        onDataRangeChange('30_days');
        return;
      case 90:
        onDataRangeChange('90_days');
        return;
      default:
        break;
    }

  }

  const onChangeDatePicker = (date: Date | null, filterParam: string) => {
    calculateDatePeriodDiff(date, filterParam);

    if (date === null) {
      const filter = { ...props.filter, page: 1 };
      delete (filter[filterParam]);
      props.setFilter(filter);
      return;
    }

    props.setFilter({ ...props.filter, [filterParam]: formatDate(date), page: 1 });

    isNeedChangeFilter();

  };

  function isNeedChangeFilter() {
    if(isPeriodMoreThan30days() && isFilterClear(props.filter)) {
      props.setFilter({ ...props.filter, ['minConvs']: 1, ['minSpend']: 1});
      borderAlarm();
    }
  }

  function borderAlarm(){
    let inp = document.getElementById('minConvs');
    //@ts-ignore
    inp.classList.add('red-border');
    let inp2 = document.getElementById('minSpend');
    //@ts-ignore
    inp2.classList.add('red-border');
    setTimeout(function(){
      let inp = document.getElementById('minConvs');
      //@ts-ignore
      inp.classList.remove('red-border');
      let inp2 = document.getElementById('minSpend');
      //@ts-ignore
      inp2.classList.remove('red-border');
    }, 1500);
  }
  function isPeriodMoreThan30days() {
    let endDate = new Date(props.filter.endDate);
    let startDate = new Date(props.filter.startDate);


    return endDate.getTime() - startDate.getTime() > 86400000*30;
  }

  function isFilterClear(filter: CampaignRealtimeBlacklistRequest) {
    return filter.inIgnoreList === undefined &&
      filter.inBlackList === undefined &&
      filter.minConvs === undefined &&
      filter.maxConvs === undefined &&
      filter.minSpend === undefined &&
      filter.maxSpend === undefined &&
      filter.minCpa === undefined &&
      filter.maxCpa === undefined &&
      filter.minCpc === undefined &&
      filter.maxCpc === undefined &&
      filter.regions === undefined &&
      filter.systems === undefined &&
      filter.formats === undefined;
  }
  const setDate = (dateStart: Date, dateEnd: Date) => {
    props.setFilter({ ...props.filter, startDate: formatDate(dateStart), endDate: formatDate(dateEnd), page: 1 });
  };

  function onDataRangeChange(value: string) {
    switch (value) {
      case '7_days':
        setDate(getDateNDaysAgo(7), new Date());
        break;
      case '30_days':
        setDate(getDateNDaysAgo(30), new Date());
        break;
      case '90_days':
        setDate(getDateNDaysAgo(90), new Date());
        break;
      case 'curr_week':
        setDate(getCurrentWeekStartDate(), new Date());
        break;
      case 'last_week':
        setDate(getLastWeekStartDate(), getLastWeekEndDate());
        break;
      case 'curr_month':
        setDate(getCurrentMonthStartDate(), new Date());
        break;
      case 'last_month':
        setDate(getLastMonthStartDate(), getLastMonthEndDate());
        break;
      case '3_months':
        setDate(getNMonthAgoStartDate(2), new Date());
        break;
      case 'curr_year':
        setDate(getCurrentYearStartDate(), new Date());
        break;
      case 'last_year':
        setDate(getLastYearStartDate(), getLastYearEndDate());
        break;
      default:
        break;
    }
  }

  function clearFilter(e: any) {
    e.preventDefault();
    props.setFilter(createCampaignRealtimeBlacklistRequest());
    let inputs = document.querySelectorAll('.filter');
    for(let input of inputs) {
      // @ts-ignore
      input.value = '';
    }
  }
  useEffect(() => {
    const onKeypress = (e: any) => {
      if(e.key === 'Enter') {
        document.getElementById('submitButton')?.click();
      }
    }

    document.addEventListener('keydown', onKeypress);

    return () => {
      document.removeEventListener('keydown', onKeypress);
    };
  }, []);

  function submitFilter(e: any) {
    e.preventDefault();
    props.setFilter({ ...props.filter, page: 1 });
    props.setSubmitFilter(true);
  }

  function copyAdSlots(campaignId: number){
    if (props.rowsChosen?.length === 0) {
      dataContext.notify(`Источники не выбраны`, 'success');
      return
    }
    const updated: any = {};
    const bundles: any = [];
    props.rowsChosen.map((el: string) => {
      let params = el.split('%%');
      bundles.push({
        domainId: Number(params[0]),
        tagid: params[1],
        systemId: Number(params[2]),
        app: (params[3] === 'true') ? 1 : 0,
        id: props.response?.message.campaign.id
      });
      updated[String(params[0])+'%%'+String(params[1])+'%%'+String(params[2])+'%%'+String(params[3])] = 1;
    });
    postCopyAdslots( {
      adSlots: bundles,
      id: campaignId
    })
      .then(r => {
        if (!isResponseCopyOK(r)) {
          r = r as AdSlotCopyPostResponseError;
          dataContext.notify(r.message, 'success');
          return;
        }
        dataContext.notify(`Источники скопированы`, 'success');
      })
  }

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const toggleCopyModal = () => {
    setIsOpenCopyModal(!isOpenCopyModal);
  };
  const onChangeFilter = (paramName: string) => {
    return (value: any) => {
      const filter = { ...props.filter, [paramName]: value?.value};
      if (value === null) {
        delete filter[paramName];
      }
      props.setFilter(filter);
    };
  };
  const onChangeFilter2 = (paramName: string, value: any) => {
    value = value.replace(',', '.');
    const filter = { ...props.filter, [paramName]: value};
    if (value === null || value == '') {
      delete filter[paramName];
    }
    props.setFilter(filter);
  }
  const selectAll = () => {
    let rows = document.querySelectorAll('tbody > tr');
    for(let i = 0; i < rows.length - 2; i++) {
      // @ts-ignore
      if(rows[i].style.background !== 'aliceblue')
        // @ts-ignore
        rows[i].click();
    }
  }

  return (
    <div>
      <div className={'pb-2 pt-2'}>
        <div className={'row'}>
          <div className={'col-sm-6 col-12 col-md-3 d-flex align-items-center'}>
            <div className={'mr-1'}>От</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'startDate')}
                        value={(new Date(props.filter.startDate))} />
          </div>
          <div className={'col-sm-6 col-12 col-md-3 d-flex align-items-center'}>
            <div className={'mr-1'}>До</div>
            <DatePicker clearIcon={null} className={'w-100 form-control'} format={'yyy-MM-dd'}
                        onChange={(date: Date) => onChangeDatePicker(date, 'endDate')}
                        value={(new Date(props.filter.endDate))} />
          </div>
          <div className='col-sm-12 col-12 col-md-3 d-flex' style={{ gap: '3px' }}>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                setDate(new Date(), new Date());
                props.setSubmitFilter(true);
              }} className='btn btn-md btn-info'>Сегодня
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                setDate(getDateNDaysAgo(1), getDateNDaysAgo(1));
                props.setSubmitFilter(true);
              }} className='btn btn-md btn-info'>Вчера
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                setDate(getDateNDaysAgo(7), new Date());
                props.setSubmitFilter(true);
              }} className='btn btn-md btn-info'>7&nbsp;дней
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={(e) => {
                e.preventDefault();
                setDate(getDateNDaysAgo(30), new Date());
                props.setSubmitFilter(true);
              }} className='btn btn-md btn-info'>30&nbsp;дней
              </button>
            </div>
            <div className={'d-flex align-items-end'}>
              <button onClick={clearFilter} className='btn btn-md btn-primary'>Очистить
              </button>
            </div>
          </div>
        </div>
        {localStorage.getItem('realtimeblacklist-page.filter.show_additional') !== null && <React.Fragment>
          <div className={'d-flex align-items-center'}>
            <div className='pr-1'>
              <p>CPA от</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPA от'}
                     value={props.filter?.minCpa}
                     onChange={e => onChangeFilter2('minCpa', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>CPA до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPA до'}
                     value={props.filter?.maxCpa}
                     onChange={e => onChangeFilter2('maxCpa', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Лиды от</p>
              <input className={'form-control filter'}
                     id={'minConvs'}
                     type={'text'}
                     placeholder={'Лиды от'}
                     value={props.filter?.minConvs}
                     onChange={e => onChangeFilter2('minConvs', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Лиды до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'Лиды до'}
                     value={props.filter?.maxConvs}
                     onChange={e => onChangeFilter2('maxConvs', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Траты от</p>
              <input className={'form-control filter'}
                     id={'minSpend'}
                     type={'text'}
                     placeholder={'Траты от'}
                     value={props.filter?.minSpend}
                     onChange={e => onChangeFilter2('minSpend', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Траты до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'Траты до'}
                     value={props.filter?.maxSpend}
                     onChange={e => onChangeFilter2('maxSpend', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>CPM от</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPM от'}
                     value={props.filter?.minCpm}
                     onChange={e => onChangeFilter2('minCpm', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>CPM до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPM до'}
                     value={props.filter?.maxCpm}
                     onChange={e => onChangeFilter2('maxCpm', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>CPC от</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPC от'}
                     value={props.filter?.minCpc}
                     onChange={e => onChangeFilter2('minCpc', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>CPC до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'CPC до'}
                     value={props.filter?.maxCpc}
                     onChange={e => onChangeFilter2('maxCpc', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Клики от</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'Клики от'}
                     value={props.filter?.minClicks}
                     onChange={e => onChangeFilter2('minClicks', e.target.value)}
              />
            </div>
            <div className='pr-1'>
              <p>Клики до</p>
              <input className={'form-control filter'}
                     type={'text'}
                     placeholder={'Клики до'}
                     value={props.filter?.maxClicks}
                     onChange={e => onChangeFilter2('maxClicks', e.target.value)}
              />
            </div>
          </div>
          <div>
            <FormGroup check>
              <Input
                id={'bl_by_ai'}
                name='check'
                type='checkbox'
                onChange={(event) => {
                  const cb = onChangeFilter('inIgnoreList');
                  cb(event.currentTarget.checked ? { value: true } : null);
                }}
                checked={props.filter?.inIgnoreList != undefined}
              />
              <Label check for={'bl_by_ai'} className='mr-5'>
                в БЛ по автоправилам
              </Label>
              <Input
                id={'bl_by_m'}
                name='check'
                type='checkbox'
                onChange={(event) => {
                  const cb = onChangeFilter('inBlackList');
                  cb(event.currentTarget.checked ? { value: true } : null);
              }}
              checked={props.filter?.inBlackList != undefined}
            />
            <Label check for={'bl_by_m'} style={{marginRight: '40px'}}>
              в БЛ по менеджеру
            </Label>
              <Input
                id={'not_bl'}
                name='check'
                type='checkbox'
                onChange={(event) => {
                  const cb = onChangeFilter('notInBlackList');
                  cb(event.currentTarget.checked ? { value: true } : null);
                }}
                checked={props.filter?.notInBlackList != undefined}
              />
              <Label check for={'not_bl'}>
                вне БЛ
              </Label>
          </FormGroup>
          </div>
        </React.Fragment>
        }
      </div>
      <div className={'d-flex align-items-end mb-1'}>
        <button id={'submitButton'} onClick={submitFilter} className='btn btn-md btn-info'>Применить
        </button>
      </div>
      {props.response && <React.Fragment>
        <div className={'d-flex align-items-end justify-content-end float-right'}>
          <button onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }} className='btn btn-sm btn-primary'>настройки
          </button>
        </div>
        {localStorage.getItem('realtimeblacklist-page.interface.show_list_buttons') !== null && <React.Fragment>
          <div className={'d-flex align-items-end justify-content-end float-right mr-1'}>
            <button onClick={(e) => {
              e.preventDefault();
              updateBlacklist();
            }} className='btn btn-sm btn-primary'>Добавить в блэклист
            </button>
          </div>
          <div className={'d-flex align-items-end justify-content-end float-right mr-1'}>
            <button onClick={(e) => {
              e.preventDefault();
              updateWhitelist();
            }} className='btn btn-sm btn-primary'>Добавить в вайтлист
            </button>
          </div>
          <div className={'d-flex align-items-end justify-content-end float-right mr-1'}>
            <button onClick={(e) => {
              e.preventDefault();
              setIsOpenCopyModal(true);
            }} className='btn btn-sm btn-primary'>Копировать в РК
            </button>
          </div>
        </React.Fragment>}
        {localStorage.getItem('realtimeblacklist-page.interface.show_ignore_ai_buttons') !== null &&
          <div className={'col-12 pt-2 pr-0'} style={{ clear: 'both' }}>
            <div className={'d-flex align-items-end justify-content-end float-right'}>
              <button onClick={(e) => {
                e.preventDefault();
              ignoreAi()
            }} className='btn btn-sm btn-primary'>Игнорировать автоправилами
            </button>
          </div>
          <div className={'d-flex align-items-end justify-content-end float-right mr-1'}>
            <button onClick={(e) => {
              e.preventDefault();
              removeFromIgnoreAi()
            }} className='btn btn-sm btn-primary'>Убрать игнор по автоправилам
            </button>
          </div>
        </div>}
        <div className={'col-12 d-flex align-items-end justify-content-end float-right pr-0 mt-1 mb-1'}>
          <button onClick={(e) => {
            e.preventDefault();
            selectAll();
          }} className='btn btn-sm btn-primary'>Выделить связки на странице
          </button>
        </div>
        <UserColumnSettingsModal isOpen={isOpenModal} toggle={toggleModal}
                                 headers={props.response.message.headers} />
        <CopyAdSlotsModal isOpen={isOpenCopyModal} toggle={toggleCopyModal} copyAdSlots={copyAdSlots}/>
      </React.Fragment>}
    </div>
  );
};