import React, { ComponentProps, useEffect, useState } from 'react';
import { Campaign } from '../../api/campaigns/campaigns.get';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createTheme, Paper, TableFooter, ThemeProvider } from '@mui/material';
import TableCellRaw from '@mui/material/TableCell';
import { AutoCpaLogs } from '../../api/campaigns/campaigns.autocpa-logs.get';
import TablePagination from '@mui/material/TablePagination';
import { formatDateTime } from '../../utils/date';

const theme = createTheme({
  components: {},
});

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export default function(props: ComponentProps<any> & { logs: AutoCpaLogs[] }) {
  const [data, setData] = useState<Array<AutoCpaLogs>>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setData(props.logs);
  }, [props.logs]);

  useEffect(() => {
    if (!props.filterBy || props.filterBy.length === 0) {
      return;
    }
    // filterDataBy(props.filterBy);
  }, [props.filterBy]);


  return (<TableContainer component={Paper}>
    <ThemeProvider theme={theme}>
      <Table id={'logs-table'} sx={{ minWidth: 650 }} aria-label='logs'>
        <TableHead>
          <TableRow>
            <TableCellRaw>Дата</TableCellRaw>
            <TableCellRaw>Событие</TableCellRaw>
            <TableCellRaw>tagid</TableCellRaw>
            <TableCellRaw>domain id</TableCellRaw>
            <TableCellRaw>app</TableCellRaw>
            <TableCellRaw>system id</TableCellRaw>
            <TableCellRaw>Описание</TableCellRaw>
            <TableCellRaw>Пред. значение</TableCellRaw>
            <TableCellRaw>Значение</TableCellRaw>
          </TableRow>
        </TableHead>
        <TableBody>
          <LogRows logs={data} page={page} rowsPerPage={100}></LogRows>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination count={data.length} rowsPerPage={rowsPerPage} onPageChange={onPageChange} page={page} />
          </TableRow>
        </TableFooter>
      </Table>
    </ThemeProvider>
  </TableContainer>);
}

function LogRows(props: { logs: AutoCpaLogs[], page: number, rowsPerPage: number }) {
  const data = props.logs.filter((v, i) => (i + 1) >= (props.page) * props.rowsPerPage && (i + 1) < (props.page + 1) * props.rowsPerPage);

  return (
    <React.Fragment>
      {data.map((log) => (
        <TableRow>
          <TableCellRaw>{formatDateTime(new Date(log.time))}</TableCellRaw>
          <TableCellRaw>{log.event}</TableCellRaw>
          <TableCellRaw>{log.tagid}</TableCellRaw>
          <TableCellRaw>{log.domainId}</TableCellRaw>
          <TableCellRaw>{log.app}</TableCellRaw>
          <TableCellRaw>{log.systemId}</TableCellRaw>
          <TableCellRaw>{log.description}</TableCellRaw>
          <TableCellRaw>{log.oldValue}</TableCellRaw>
          <TableCellRaw>{log.newValue}</TableCellRaw>
        </TableRow>
      ))}
    </React.Fragment>
  );
}