//@ts-nocheck
import qs from 'qs';
import axios from 'axios';
import { apiKey } from '../apiKey';

const PATH_URL = '/ad-slot/copy';

export type AdSlotCopyPostRequest = {
  id: number,
  adSlots: AdSlots[]
}

export type AdSlots = {
  tagid: string
  id: number
  domainId: number
  systemId: number
  app: 1 | 0,
}

export type AdSlotCopyPostResponseOk = {
  'status': number,
  'message': AdSlotCopyPostResponseMessageOk,
}

export type AdSlotCopyPostResponseMessageOk = {
  data: null,
}

export type AdSlotCopyPostResponseError = {
  'status': number,
  'message': AdSlotCopyPostResponseMessageError,
}

export type AdSlotCopyPostResponseMessageError = string

// @ts-ignore
const key = new apiKey();

export default function postCopyAdslots(request: AdSlotCopyPostRequest): Promise<AdSlotCopyPostResponseOk | AdSlotCopyPostResponseError> {
  return axios.post(process.env.REACT_APP_API_URL + PATH_URL, request, {
    headers: {
      apiKey: key.get(),
    },
  }).then(r => {
    return <AdSlotCopyPostResponseMessageOk | AdSlotCopyPostResponseError>r.data;
  }).catch(e => {
    const resp: AdSlotCopyPostResponseError = {
      status: 0,
      message: e.response ? e.response.data.message : e.message,
    };
    return resp;
  });
}

export function isResponseCopyOK(response: AdSlotCopyPostResponseOk | AdSlotCopyPostResponseError): boolean {
  return typeof response.message !== 'string';
}