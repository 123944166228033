import React, { useState } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import bn from '../../utils/bemnames';
import {
  MdDashboard, MdFindInPage,
  MdHistory, MdInsertChart,
  MdMenu, MdNewReleases,
  MdNotifications,
  MdExitToApp,
} from 'react-icons/md';
import setDomainCookie from '../../utils/cookies';
import { IoMdSnow } from 'react-icons/all';
import { isWinter } from '../../utils/date';

const bem = bn.create('sidebar');

const navItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/campaigns/analytics', name: 'Analytics', exact: true, Icon: MdInsertChart },
  { to: '/campaigns/alerts', name: 'Alerts', exact: true, Icon: MdNotifications },
  { to: '/eva/alerts', name: 'Eva', exact: true, Icon: MdNewReleases },
  { to: '/autocpa/logs', name: 'Autocpa logs', exact: true, Icon: MdFindInPage },
  { to: '/campaigns/smartbids/history', name: 'Smartbid history', exact: true, Icon: MdHistory },
  { to: '/offers', name: 'Offers', exact: true, Icon: MdDashboard },
];

const logout = () => {
  setDomainCookie('apikey', '', 0);
  window.location.replace('/login');
}

const switchSnow = () => {
  if (document.getElementsByClassName('snowflakes-box')[0].classList.contains('d-none')) {
    document.getElementsByClassName('snowflakes-box')[0].classList.remove('d-none');
    document.getElementsByClassName('snowball-box')[0].classList.remove('d-none');
  } else {
    document.getElementsByClassName('snowflakes-box')[0].classList.add('d-none');
    document.getElementsByClassName('snowball-box')[0].classList.add('d-none');
  }
}

export default function Navigation(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar expand='lg' style={{ backgroundColor: '#6a82fb' }} {...args}>
        <NavbarBrand className={'text-white'} href='/'>Admin Panel</NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <MdMenu className={'text-white'} />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className='me-auto' navbar>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink style={{color: '#fff'}}
                  id={`navItem-${name}-${index}`}
                  className='text-uppercase pl-2'
                  tag={NavLink}
                  to={to}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className=''>{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>

          <div className={bem.e('nav-item', 'ml-auto d-flex')}>
            {isWinter() && (
              <BSNavLink to={'#'} className='text-uppercase pl-2 text-white' tag={NavLink} onClick={switchSnow}>
                <IoMdSnow className={bem.e('nav-item-icon')} />
              </BSNavLink>
            )}
            <BSNavLink to={'#'} className='text-uppercase pl-2 text-white' tag={NavLink} onClick={logout}>
              <MdExitToApp className={bem.e('nav-item-icon')} />
            </BSNavLink>
          </div>

        </Collapse>
      </Navbar>
    </div>
  );
}