import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { isWinter } from './utils/date';

const root = createRoot(document.getElementById('root')!);
root.render(<App />)



const loadScript = (): void => {
  let scriptEle = document.createElement("script");
  scriptEle.src = "js/_snow.min.js";
  scriptEle.async = true;
  document.body.appendChild(scriptEle);
}

if (isWinter()) {
  window.onload = loadScript;
}