import React, { useContext, useEffect, useState } from 'react';
import appContext from '../../utils/context/appContext';
import Page from '../../components/Page';
import Filter from '../../components/AutoCpaLogs/Filter';
import get, {
  AutoCpaLogs,
  AutoCpaLogsRequest,
  AutoCpaLogsResponseError,
  AutoCpaLogsResponseOk,
  isResponseOK,
  recognizeRequest,
} from '../../api/campaigns/campaigns.autocpa-logs.get';
import MaterialDataTable from '../../components/AutoCpaLogs/MaterialDataTable';
import post, {
  AutoCpaAddBundlesResponseError,
  isResponseOK as isAddBundlesResponseOk,
} from '../../api/campaigns/campaigns.autocpa-add-bundles.post';

const AutoCpaLogsPage = function() {
  const [filter, setFilter] = useState<AutoCpaLogsRequest>(recognizeRequest);
  const [campaignId, setCampaignId] = React.useState<string>('');
  const [response, setResponse] = React.useState<AutoCpaLogsResponseOk | null>(null);
  const [data, setData] = React.useState<AutoCpaLogs[]>([]);
  const dataContext = useContext(appContext);
  const [bundles, setBundles] = React.useState<string>('');

  function filterDataBy(str: string) {
    if (!response) {
      return;
    }
    if (str.length === 0) {
      const logs = [...response.message.data.logs];
      setData(logs);
      return;
    }
    const newData = response.message.data.logs.filter((log: AutoCpaLogs) => {
      return log.time.toLowerCase().includes(str.toLowerCase()) ||
        log.event.toString().includes(str) ||
        log.tagid.toString().includes(str) ||
        log.domainId.toString().includes(str) ||
        log.app.toString().includes(str) ||
        log.systemId.toString().includes(str) ||
        log.description.toString().includes(str);
    });
    if (newData.length === data.length) {
      return;
    }
    setData(newData);
  }

  function requestData() {
    dataContext.setIsLoading(true);
    get(Number(campaignId), filter).then(r => {
      if (!isResponseOK(r)) {
        r = r as AutoCpaLogsResponseError;
        dataContext.notify(r.message, 'error');
        return;
      }
      r = r as AutoCpaLogsResponseOk;
      setResponse(r);
    }).catch((e) => {
      dataContext.notify(e.message, 'error');
    }).finally(() => {
      dataContext.setIsLoading(false);
    });
  }

  useEffect(() => {
    if (campaignId.length === 0 || campaignId === '') {
      return;
    }
    requestData();
  }, [campaignId, filter]);

  useEffect(() => {
    if (response === null) {
      return;
    }
    setData(response.message.data.logs)
  }, [response]);

  const addBundles = () => {
    dataContext.setIsLoading(true);
   if (bundles < '1')  {
     dataContext.notify("Некорректное значение количества связок", 'error');
   }
   post(Number(campaignId), {bundles: Number(bundles)}).then(r => {
     if (isAddBundlesResponseOk(r)) {
       dataContext.notify("Запрос на добавление связок успешно отправлен", 'success');
       setBundles('');
       return;
     }
     r = r as AutoCpaAddBundlesResponseError;
     dataContext.notify(r.message, 'error');
   }).catch((e) => {
     dataContext.notify(e.message, 'error');
   }).finally(() => {
     dataContext.setIsLoading(false);
   });
  }

  return (<Page
    className='AutoCpaLogsPage'
    title='Автопролив: логи'
  >
    <Filter filter={filter} setFilter={setFilter} campaignId={campaignId} setCampaignId={setCampaignId} />
    {response && <div>
      <h5>
        Кампания: {`[${response.message.data.campaign.id}] ${response.message.data.campaign.name}`}
      </h5>
      <div className='row'>
        <div className='col-lg-4'>
          Поиск
          <input type='text' className={'form-control'} onInput={(e) => filterDataBy(e.currentTarget.value) } />
        </div>
        <div className='col-lg-4'>
          Добавить связок
          <div className='row'>
            <div className='col-lg-6 mb-0 mt-1'>
              <input type='text' className={'form-control'} value={bundles} onInput={(e) => setBundles(e.currentTarget.value) } />
            </div>
            <div className='col-lg-6 mt-1'>
              <button className={'btn btn-success'} onClick={addBundles} disabled={bundles == '' || bundles == '0'}>применить</button>
            </div>
          </div>
        </div>
      </div>
      <MaterialDataTable logs={data} />
    </div>}
  </Page>);
};
export default AutoCpaLogsPage;
