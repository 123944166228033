import React, { ComponentProps, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import appContext from '../../utils/context/appContext';
import pageContext from '../../utils/context/realtimeBlacklistContext';
import { MdClose } from 'react-icons/md';
import {
  CampaignRealtimeBlacklistRequest,
  createCampaignRealtimeBlacklistRequest,
} from '../../api/campaigns/campaigns.realtime-blacklist.get';
import { formatDate } from '../../utils/date';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';

export default function(props: ComponentProps<any> & { toggle: () => {}, isOpen: boolean, copyAdSlots: () => {}}) {
  const context = useContext(pageContext);
  const dataContext = useContext(appContext);
  const [campaignId, setCampaignId] = useState<number>();

  function copyAdSlots(){
    props.copyAdSlots(campaignId);
  }

  return <Modal
    size={'md'}
    isOpen={props.isOpen}
    toggle={() => props.toggle()}
    className={props.className}>
    <ModalHeader close={<MdClose style={{ cursor: 'pointer' }} onClick={props.toggle} />}
                 toggle={() => props.toggle()}>Плохой cpa</ModalHeader>
    <ModalBody>
      <form className='row col-12'>
        <label className={'w-100'}>
          Введите id кампании
          <input type='text' className={'form-control'} onChange={e => setCampaignId(toNumber(e.currentTarget.value))} value={campaignId} />
        </label>
      </form>
    </ModalBody>
    <ModalFooter>
      <button className={'btn btn-primary'} onClick={(e) => {
        e.preventDefault();
        copyAdSlots();
        props.toggle();
      }}>
        Копировать
      </button>
      <button className={'btn btn-secondary'} onClick={(e) => {
        e.preventDefault();
        props.toggle();
      }}>
        Отмена
      </button>
    </ModalFooter>
  </Modal>;
}